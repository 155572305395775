<!-- 不接受报价的弹窗 -->

<template>
	<div class="OperationTips">
		<div class="OperationTips_container">
			<div class="OperationTips_container_title">
				<p><span>Operation tips</span></p>
				<img class="OperationTips_container_title_close" src="../../../assets/images/supplier/quotation/if-close.png" alt="" @click="CloseDialog('close')">
			</div>

			<div class="OperationTips_container_form">
				<div class="OperationTips_container_form_tip">
					<img src="../../../assets/images/customer/tip.png" alt="">
					<span>{{ props.detael.promptContent }}</span>
				</div>
				<div class="OperationTips_container_form_btn">
					<div class="OperationTips_container_form_btn_not" @click="CloseDialog('close')">No,Keep it</div>
					<div class="OperationTips_container_form_btn_confirm" @click="CloseDialog('confirm')">Yes,Decline it</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup name="OperationTips">
import { defineEmits, watch } from 'vue';

// 接受大货单报价单页面传递的数据
const props = defineProps(['detael'])
// 监听props数组的变化
watch(() => props.detael, (val) => {
	console.log('监听变化的值', val)
}, { deep: true })

// 关闭弹窗
const emit = defineEmits(['customEvent'])
const CloseDialog = (msg) => {
	emit('customEvent', msg)
}

</script>

<style lang="scss" scoped>
@use '../../../assets/scss/elForm.scss' as *;

.OperationTips {

	position: fixed;
	width: 100vw;
	height: 100vh;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
	line-height: 1;
	text-align: left;
	overflow-y: scroll;

	&_container {
		width: 890px;
		margin: 80px auto 0;
		background-color: #fff;
		border-radius: 8px;
		overflow: hidden;
		margin-bottom: 300px;

		&_title {
			display: flex;
			justify-content: space-between;
			padding: 30px;
			background-color: rgba(248, 248, 248, 1);

			P {
				font-size: 20px;
				margin: 0;
				font-weight: 700;
				color: rgba(24, 24, 24, 1);
			}

			&_close {
				width: 20px;
				height: 20px;
				cursor: pointer;

			}
		}

		&_form {
			// width: 400px;
			margin: 50px auto;

			&_tip {
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: 0;
				margin: 0 80px;

				img {
					width: 60px;
					height: 60px;
					margin-bottom: 50px;
				}

				span {
					color: rgba(24, 24, 24, 1);
					font-size: 20px;
					line-height: 2;
					text-align: center;
					font-weight: 700;
					margin-bottom: 30px;
				}
			}

			&_btn {
				width: 400px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 50px auto;

				&_not,
				&_confirm {
					font-size: 14px;
					padding: 15px 30px;
					border-radius: 4px;
					font-weight: 700;
					cursor: pointer;
				}

				&_not {
					color: rgba(255, 151, 0, 1);
					border: 1px solid rgba(255, 151, 0, 1);

					&:hover {
						color: #fff;
						background-color: rgba(255, 151, 0, 1);
					}
				}

				&_confirm {
					color: #fff;
					background-color: rgba(39, 174, 96, 1);
					margin-left: 20px;

					&:hover {
						background-color: rgba(12, 150, 69, 1);
					}
				}
			}

		}
	}
}
</style>
